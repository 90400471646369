//@import '@severalnines/bar-frontend-components/dist/index.less';
.App {
}

// TODO: update components
.AppHeader {
    align-items: baseline;
    &.ant-layout-header {
        padding: 0;
    }
}

/*override inner layout until discover whats happening with footer*/
.AppLayout_inner-layout.AppLayout_inner-layout--header-fixed {
    min-height: 100vh;
}

/*
Overriding sorter headers until we find out we break something elss
if we dont override, then the sorter colums overlaps the activity quick view
 */
.ant-table-cell.ant-table-column-has-sorters {
    z-index: 0;
}

.AppContent {
    //background-color: @layout-body-background !important;
}

.ant-layout {
    //background-color: @layout-body-background;
}
// @todo: improve components to accept customization
// used for wizards
.BoxSelect_item-wrapper {
    border-radius: @border-radius-medium !important;
    .WizardSelectCardStep_Item {
        background: transparent !important;

        // @todo: improve common component to customize icon
        .WizardSelectCardStep_Item_icon {
            color: @primary-color;
        }

        // @todo: improve common component to customize everything
        &:hover {
            .WizardSelectCardStep_Item_title {
                color: @primary-color;
            }
        }
    }
}

// @todo: fix components to remove background white
.Wizard-step-body {
    background: transparent !important;
}

// change checkbox indeterminate status to circle
.ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    border-radius: 50%;
}

@hack: true; @import "/opt/buildagent/work/b4e4dbd91a82c3f7/src/theme.less";